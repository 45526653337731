
import { defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'Login',
  setup () {
    const Vue3GoogleOauth = inject('Vue3GoogleOauth')
    return {
      Vue3GoogleOauth
    }
  }
})
